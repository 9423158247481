import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -120;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    return (
        <>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to="/about#about"
                scroll={el => scrollWithOffset(el)}
            >
                About
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to="/platform#services"
                scroll={el => scrollWithOffset(el)}
            >
                Platform
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to="/why-evolinq#why-evolinq"
                scroll={el => scrollWithOffset(el)}
            >
                Why us?
            </HashLink>
            <HashLink
                className="px-4 navbar-text cursor-pointer"
                smooth
                to="/careers#careers"
                scroll={el => scrollWithOffset(el)}
            >
                Careers
            </HashLink>
            <HashLink
                className="text-white custom-bg-color inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
                smooth
                to="/demo#demo"
                scroll={el => scrollWithOffset(el)}
            >
                Book a Demo
            </HashLink>
        </>
    );
};

export default NavLinks;
