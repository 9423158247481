import React, { useEffect, useState } from 'react';

const Dialog = () => {
  const [content, setContent] = useState(<div className="spinner"></div>);
  const [excelId, setexcelId] = useState(null);
  const [token, setToken] = useState(null);
  const [apiUrl, setApiUrl] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const messageId = urlParams.get("message_id");
    const orderKey = urlParams.get("order_key");
    const token = urlParams.get("token");
    const apiUrl = urlParams.get("api_url");
    const excelId = urlParams.get("excel_id");

    setToken(token);
    setApiUrl(apiUrl);
    setexcelId(excelId);

    let urlToFetch = null;
    if (messageId !== null){
      urlToFetch = `${apiUrl}/excel/get-email-related?token=${token}&message_id=${encodeURIComponent(messageId)}&response_type=html`;
    }
    else if (orderKey !== null){
      urlToFetch = `${apiUrl}/excel/get-email-related?token=${token}&order_key=${orderKey}&response_type=html`;
    }

    if (urlToFetch) {
      fetch(urlToFetch, {
        method: "GET",
      })
        .then(response => response.text())
        .then(htmlContent => {
          setContent(<div dangerouslySetInnerHTML={{ __html: htmlContent }} />);
        })
        .catch(error => {
          console.error("Error fetching content:", error);
          setContent(<p>Error loading content.</p>);
        });
    } else {
      setContent(<p>Error: No messageId or orderKey provided</p>);
    }
  }, []);

  return (
    <div>
      <div>
      {excelId !== null && token !== null && apiUrl !== null && (
        <a className="back-button" href={`/supplier-status-table?token=${encodeURIComponent(token)}&excel_id=${encodeURIComponent(excelId)}&api_url=${encodeURIComponent(apiUrl)}`}>
          Back
        </a>
      )}
      </div>
      <div id="content" style={{ overflowX: "scroll" }}>
        {content}
      </div>
    </div>
  );
};

export default Dialog;
