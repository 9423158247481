import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
import swal from "sweetalert";
import { Helmet } from 'react-helmet';

const DemoProduct = () => {
  useDocTitle("Evolinq | Request a Demo");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get('https://submit-form.com/zZBXqu2Bx', {
        params: {
          name,
          email,
          phone,
          message,
        },
      });
      swal("Success", "We've got your message", "success");
      clearInput();
    } catch (error) {
      console.error("There was an error!", error);
      swal("Error", "There was an error sending your message. Please contact us at sales@evolinq.io", "error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Evolinq</title>
        <meta name="description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Management" />
        <link rel="canonical" href="https://www.evolinq.io/demo" />
        <meta property="og:title" content="Home - Evolinq" />
        <meta property="og:description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta property="og:url" content="https://www.evolinq.io" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <NavBar />
      </div>
      <div id="demo" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
          <form id="DemoProductForm" onSubmit={handleSubmit} autoComplete="on">
            <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mx-auto rounded-2xl shadow-2xl">
              <div className="flex">
                <h1 className="font-bold text-center lg:text-left custom-blue uppercase text-4xl">
                  Request a free demo
                </h1>
              </div>
              {errors && <p className="text-red-500 text-sm">{errors.products}</p>}
              <div className="grid grid-cols-1 gap-5 md:grid-cols-1 mt-5">
                <div className="w-full md:w-1/2">
                  <input
                    name="name"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Full Name*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onKeyUp={clearErrors}
                    required
                    autoComplete="name"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                </div>
                <div className="w-full md:w-1/2">
                  <input
                    name="email"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={clearErrors}
                    required
                    autoComplete="email"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="w-full md:w-1/2">
                  <input
                    name="phone"
                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    type="tel"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyUp={clearErrors}
                    autoComplete="tel"
                  />
                  {errors && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                </div>
              </div>
              <div className="my-4">
                <textarea
                  name="message"
                  placeholder="Message"
                  className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                ></textarea>
                {errors && <p className="text-red-500 text-sm">{errors.message}</p>}
              </div>
              <div className="my-2 w-1/2 lg:wClients-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide custom-bg-color hover:custom-bg-color text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DemoProduct;
