import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import logoImg from '../images/evolinq-blue-logo.svg';

const Hero = () => {
    return (
        <>
            <div className="hero min-h-screen pt-24 lg:pt-40" id='hero'>
                <div>
                    <NavBar />
                </div>

                <div className="m-auto overflow-hidden mx-4 mt-6 lg:mt-5 p-2 md:p-12 h-full flex flex-col lg:flex-row items-center lg:items-center" data-aos="zoom-in">
                    <div className="w-full flex justify-center lg:w-2/3 order-1 lg:order-2 lg:ml-20 mb-12" data-aos="fade-up" data-aos-delay="700">
                        <img alt="card img" className="rounded-t duration-1000 w-2/3 lg:w-3/4" src={logoImg} />
                    </div>
                    <div id='hero' className="flex flex-col justify-center text-center lg:text-left lg:w-2/3 order-2 lg:order-1 py-16 lg:pl-8">
                        <h1 className="mb-5 md:text-7xl text-4xl font-extrabold">
                            AI-Driven<br />Supply Chain <br />Management.
                        </h1>
                        <div className="md:text-2xl text-xl font-semibold tracking-tight mb-5" id="slogan">
                            Automating your entire procurement processes<br />
                            with all of your suppliers.<br />
                            Zero-Integrations required.<br />
                            Nothing is required on the suppliers side.
                        </div>
                        <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                            <Link to="/demo" className="text-white bg-[#166AEA] hover:bg-[#145BCF] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                Book a Demo
                                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 110-2h11.586l-4.293-4.293a1 1 010-1.414z" clipRule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;
