import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import About from "./pages/About";
import Platform from "./pages/Platform";
import WhyUs from "./pages/WhyUs";
import Careers from "./pages/Careers";
import DemoProduct from "./pages/Demo";
import Dashboard from "./pages/Dashboard";
import Dialog from './pages/DisplayEmail';
import SupplierStatusTable from './pages/SupplierStatusTable';
import NotFound from './pages/NotFound';

import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import NavBar from "./components/Navbar/NavBar";
import Footer from "./components/Footer";

const Layout = ({ children }) => {
  const location = useLocation();
  const hideHeaderFooter = ["/dashboard", "/dashboard/", "/display-email", "/display-email/", "/supplier-status-table", "/supplier-status-table/"].includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen">
      {!hideHeaderFooter && <NavBar />}
      <div className="flex-grow">
        {children}
      </div>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
};

const App = () => {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useDocTitle("Evolinq");

  return (
    <Router>
      <ScrollToTop>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/platform" element={<Platform />} />
            <Route path="/why-evolinq" element={<WhyUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/demo" element={<DemoProduct />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/display-email" element={<Dialog />} />
            <Route path="/supplier-status-table" element={<SupplierStatusTable />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </ScrollToTop>
    </Router>
  );
};

export default App;
