import React from "react";
import Cta from "../components/Cta";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import Services from "../components/Services";
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Evolinq</title>
        <meta name="description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta name="keywords" content="Evolinq, AI, Supply Chain, Management" />
        <link rel="canonical" href="https://www.evolinq.io/why-evolinq#why-evolinq" />
        <meta property="og:title" content="Home - Evolinq" />
        <meta property="og:description" content="Welcome to Evolinq, your AI-driven solution for supply chain management." />
        <meta property="og:url" content="https://www.evolinq.io" />
        <meta property="og:image" content="https://www.evolinq.io/images/og-image.jpg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Hero />
      <Intro />
      <Services />
      <Cta />
    </>
  );
};

export default Home;
