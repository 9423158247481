import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../index.css';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const StatusBox = ({ title, count, color }) => (
  <Box
    sx={{
      background: color,
      color: 'white',
      borderRadius: '5px',
      padding: '15px',
      textAlign: 'center',
      marginRight: '15px',
      marginLeft: '15px',
      width: '120px',
      height: '120px',
      transition: 'transform 0.3s ease',
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
      {title}
    </Typography>
    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
      {count}
    </Typography>
  </Box>
);


const SupplierStatusTable = () => {
  const [statuses, setStatuses] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const excelId = urlParams.get("excel_id");
  const apiUrl = urlParams.get("api_url");

  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const response = await fetch(`${apiUrl}/excel/emails-status?token=${token}&excel_id=${excelId}`, requestOptions);
        const statuses = await response.json();

        setStatuses(statuses);

        const total = statuses.length;
        const successCount = statuses.filter(status => status.status === "success").length;
        const errorCount = statuses.filter(status => status.status === "error").length;
        const waitingCount = statuses.filter(status => status.status === "in_line" || status.status === "in_progress").length;
        const progressPercentage = ((successCount + errorCount) / total) * 100;
        setProgress(progressPercentage);
        setIsLoading(false);

        if (progressPercentage === 100) {
          clearInterval(fetchStatusesInterval);
        }
      } catch (error) {
        console.error('Error fetching statuses:', error);
        setIsLoading(false);
      }
    };

    const fetchStatusesInterval = setInterval(fetchStatuses, 500);

    return () => clearInterval(fetchStatusesInterval);
  }, [token, excelId, apiUrl]);

  const openEmail = (messageId) => () => {
    if (messageId !== null){
      window.location = `/display-email?api_url=${encodeURIComponent(apiUrl)}&message_id=${encodeURIComponent(messageId)}&token=${token}&excel_id=${excelId}`;
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "in_line":
        return <><span style={{ color: "gray" }}>●</span> Waiting</>;
      case "in_progress":
        return <><span style={{ color: "orange" }}>●</span> In Progress</>;
      case "success":
        return <><span style={{ color: "green" }}>●</span> Success</>;
      case "error":
        return <><span style={{ color: "red" }}>●</span> Error</>;
      default:
        return "";
    }
  };

  const successCount = statuses.filter(status => status.status === "success").length;
  const errorCount = statuses.filter(status => status.status === "error").length;
  const waitingCount = statuses.filter(status => status.status === "in_line" || status.status === "in_progress").length;

  return (
    <div>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <div className="table-container">
          <div>
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={progress} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
              <StatusBox title="Error" count={errorCount} color="#f44336" />
              <StatusBox title="Waiting" count={waitingCount} color="#757575" />
              <StatusBox title="Success" count={successCount} color="#4caf50" />
            </Box>
            <table className="status-table">
              <thead>
                <tr>
                  <th>SUPPLIER NAME</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {statuses.map((status, index) => (
                  <tr key={index}>
                    <td 
                      onClick={openEmail(status.message_id)} 
                      style={status.message_id ? { color: 'blue', textDecoration: 'underline', cursor: 'pointer' } : {}}
                    >
                      {status.supplier}
                    </td>
                    <td>
                      <div className={status.message ? "tooltip" : ""}>
                        <span 
                          style={{ cursor: status.message ? 'help' : 'auto' }}
                        >
                          {getStatusIcon(status.status)}
                        </span>
                        {status.message && (
                          <span className="tooltiptext">{status.message}</span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> 
      )}
    </div>
  );
};

export default SupplierStatusTable;
