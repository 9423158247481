import React from 'react';
import img1 from '../images/artificial-intelligence.png';
import img2 from '../images/infographic.png';
import img3 from '../images/truck.png';
import img4 from '../images/supply-chain-management.png';

const Services = () => {

    return (
       
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl custom-blue uppercase font-bold">Discover the Future</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                    </div>

                    <div data-aos="fade-down" data-aos-delay="600" style={{marginRight: 20 + 'vw', marginLeft: 20 + 'vw'}}>
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <img src={img1} alt="Artificial Intelligence" width={100} style={{margin: "auto"}}/>
                                <div className="m-2 text-sm">
                                    <h2 className="font-semibold my-4 text-2xl text-center">AI Forecasts</h2>
                                    <p className="text-md font-medium">
                                    Gain early insights on potential supply chain crises and manage supplier performance with advanced analytics, ensuring you can react swiftly and make informed decisions.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <img src={img2} alt="Infographic" width={100} style={{margin: "auto"}}/>
                                <div className="m-2 text-sm">
                                    <h2 className="font-semibold my-4 text-2xl text-center">Workflow Visualization</h2>
                                    <p className="text-md font-medium">
                                    Monitor every stage of your procurement process in real-time, allowing you to identify bottlenecks, optimize workflows, and enhance overall operational efficiency.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-sm">
                                    <img src={img3} alt="Truck" width={100} style={{margin: "auto"}}/>
                                    <h2 className="font-semibold my-4 text-2xl text-center">Supply Oversight</h2>
                                    <p className="text-md font-medium">
                                    Utilize comprehensive tools to manage and evaluate supplier performance, and seamlessly connect procurement processes with production to ensure smooth operations.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <img src={img4} alt="Supply Chain Management" width={100} style={{margin: "auto"}}/>
                                <div className="m-2 text-sm">
                                    <h2 className="font-semibold my-4 text-2xl text-center">Supply Chain Solutions</h2>
                                    <p className="text-md font-medium">
                                    redict market demand accurately, identify opportunities for cost reduction, and implement strategies to keep your business ahead of the competition in the industry.
                                    </p>
                                </div>
                            </div>                   
                        </div>
                    </div>
            </section>

            <section id="why-evolinq">
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center pl-4 pr-4">
                            <div className='custom-blue mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-2xl  
                            font-bold"><span className='font-black'>Why Trust Us
                            </span></h3>
                            <div>
                                <p className='my-3 text-l text-gray-600 font-semibold'>
                                Trust us with your procurement needs, as we manage hundreds of millions of dollars in procurement annually, ensuring optimal efficiency and cost-effectiveness. We collaborate with the largest and most reputable vendors globally, giving you access to top-tier products and services. Our strong industry recommendations and proven track record highlight our expertise.
</p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center pl-4 pr-4">
                            <div className='custom-blue mb-4'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path></svg>
                            </div>
                            <h3 className="text-2xl "><span className='font-black'>Effortless Integration</span></h3>
                            <div>
                                <p className='my-3 text-l text-gray-600 font-semibold'>No integration is required, and no training is necessary for your employees or suppliers. Evolinq seamlessly integrates into your existing workflows, allowing you to streamline your purchase order processes without any disruption. Our user-friendly platform is designed to adapt effortlessly to your current systems, ensuring a smooth transition and immediate efficiency gains.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;