import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <>
      <footer className="w-full">
        <div className="max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-6">
          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-black-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}{" "}
                <HashLink to="#" className="hover:text-gray-900">
                  Evolinq
                </HashLink>. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
