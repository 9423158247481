import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import { useDocTitle } from '../components/CustomHook';
import { Helmet } from 'react-helmet';

const Careers = () => {
  useDocTitle("Evolinq | Careers");

  return (
    <>
      <Helmet>
        <title>Evolinq | Careers</title>
        <meta name="description" content="Join the Evolinq team and help us revolutionize supply chain management with AI." />
        <meta name="keywords" content="Evolinq Careers, AI Jobs, Supply Chain Jobs" />
        <link rel="canonical" href="https://www.evolinq.io/careers" />
      </Helmet>
      <NavBar />
      <div 
        id="careers" 
        className="py-12 bg-cover bg-center min-h-screen"
      >
        <section data-aos="zoom-in-down">
          <div style={{backgroundColor: "black", backgroundImage: "linear-gradient(black , #1565C0)", paddingTop: "70px", paddingBottom: "70px", marginTop: "50px", marginBottom: "50px"}}>
          <div className="my-4 py-4">
            <h1 className="my-2 text-center text-6xl text-white font-bold">Careers</h1>
            <h3 className="text-center text-3xl text-white mb-10 mt-10">Join the Future of Supply Chain Innovation</h3>
          </div>
          </div>
          <h4 className="text-center text-lg custom-blue mb-12 pl-4 pr-4">
          Join the Evolinq Family and Make a Lasting Impact on the Supply Chain World. <br /> Be part of an innovative team, working with cutting-edge technologies to drive transformative change and excellence.
          </h4>
          <div data-aos="fade-down" data-aos-delay="600" className="mx-auto max-w-6xl">
            <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5">
              
              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl p-5 group">
                <h3 className="font-semibold text-2xl text-center custom-blue mb-4 group-hover:custom-blue">Customer Success Specialist</h3>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>Fluent in Hebrew and English</li>
                  <li>Technologically adept with a quick learning ability</li>
                  <li>Strong analytical and problem-solving skills</li>
                  <li>Excellent interpersonal and communication skills</li>
                  <li>Proficient in delivering training sessions</li>
                  <li>Available during daytime hours</li>
                  <li>Patient and customer-oriented</li>
                </ul>
                <h4 className="font-semibold text-xl text-left custom-blue mt-4 group-hover:custom-blue">Preferred Qualifications:</h4>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>Experience in procurement or supply chain management</li>
                  <li>Experience in presenting Release Notes to customers</li>
                </ul>
                <div className="text-white custom-bg-color text-center hover:custom-bg-color inline-flex w-auto px-6 py-3 shadow-xl rounded-xl mx-auto mt-5"
                  onClick={() => window.open('mailto:careers@evolinq.io?subject=Evolinq Career Application: Customer Success Specialist')}
                  style={{ cursor: 'pointer' }}
                >
                Apply
              </div>
              </div>

              <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 rounded-lg shadow-2xl p-5 group">
                <h3 className="font-semibold text-2xl text-center custom-blue mb-4 group-hover:custom-blue">Full Stack Senior Developer</h3>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>Strong experience in full-stack development</li>
                  <li>Proficiency in Python and LLMs (Large Language Models)</li>
                  <li>Extensive knowledge of React and TypeScript</li>
                  <li>Experience with AWS cloud services</li>
                  <li>Proficiency in MongoDB for database management</li>
                  <li>Strong problem-solving skills and analytical thinking</li>
                  <li>Excellent communication and teamwork abilities</li>
                </ul>
                <h4 className="font-semibold text-xl text-left custom-blue mt-4 group-hover:custom-blue">Preferred Qualifications:</h4>
                <ul className="list-disc list-inside text-md font-medium">
                  <li>Experience with the Retool platform</li>
                  <li>Experience with prompt engineering</li>
                </ul>
                <div className="text-white custom-bg-color text-center hover:custom-bg-color inline-flex w-auto px-6 py-3 shadow-xl rounded-xl mx-auto mt-5"
                  onClick={() => window.open('mailto:careers@evolinq.io?subject=Evolinq Career Application: Full Stack Senior Developer')}
                  style={{ cursor: 'pointer' }}
                >
                Apply
              </div>
              </div>
            </div>
          </div>
        </section>
        <p className="text-center mt-12 text-gray-700">
        For further information feel free to reach us at <a href="mailto:careers@evolinq.io" className="custom-blue hover:underline">careers@evolinq.io</a>.
      </p>
      </div>

    </>
  );
};

export default Careers;
